import React, { ReactElement } from "react"
import { Image } from "../../../types/images"
import MoreLink from "../../components/MoreLink"
import WorkDetail from "../../components/WorkDetail"

const imgs: Image[] = [
  {
    src: "MoneyMentors/money-tips-iphone_fcvvug.jpg",
    alt: "iPhone",
    id: "1",
    chrome: false,
  },
  {
    src: "MoneyMentors/money-mentors-home_dpjgb1.jpg",
    alt: "Home",
    id: "2",
    chrome: true,
  },
  {
    src: "MoneyMentors/debt-help_cvwoss.jpg",
    alt: "Debt Help",
    id: "3",
    chrome: true,
  },
  {
    src: "MoneyMentors/success-stories_hps56o.jpg",
    alt: "Success Stories",
    id: "4",
    chrome: true,
  },
]

export default function MoneyMentors(): ReactElement {
  return (
    <WorkDetail title="Money Mentors" images={imgs}>
      <p className="lead">
        As an Alberta-based credit counselling and financial education agency,
        Money Mentors helps Albertans get out of debt, manage their money, and
        plan for retirement.
      </p>
      <p>
        As part of a recent rebranding effort, Money Mentors was looking to
        update their online presence with a bespoke website. The website
        didn&apos;t just have to be custom but also rich with relevant content
        that is easy to find and accessible from any device.
      </p>
      <p>
        In addition to helping users find relevant information, the site also
        needed to serve as a tool for the staff and for users. The site was
        built in a CMS that allows staff to update content easily. A custom
        booking portion of the site was also developed that reduces strain on
        employees by allowing users to create bookings on their own time.
        Multiple avenues were also built into the site that make it easy for
        someone looking for help to reach out with minimal information and
        routes notifications to the appropriate staff in order to best serve the
        customer.
      </p>
      <MoreLink to="https://moneymentors.ca/" />
    </WorkDetail>
  )
}
